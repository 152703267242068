.contextMenu {
  fill: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.6);
}

.menuEntry {
  cursor: pointer;
}

.menuEntry:hover {
  fill: rgba(230, 230, 230, 0.85);
}

.menuEntry text {
  font-size: 12px;
  fill: #666666;
}

.menuEntry rect {
  stroke: rgba(212, 212, 212, 0.8);
}

.fontStyle {
    font-weight:bold !important;
}

.dndflow {
    flex-direction: column;
    display: flex;
    height: 100%;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow header {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside > * {
    margin-bottom: 10px;
    cursor: grab;
}

.dndflow aside .description {
    cursor: default;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 80vh;
}

.customNode {
    padding: 22px;
    max-width: 230px;
    overflow-wrap: break-word;
    border-radius: 8px;
    box-shadow: 4px 4px 9px 0px #d8d8d8
}
.customNode .placeholder {
    background: #8a95a1;
    min-width: 100%;
}

.customNode:hover {
    background: #8a95a1;
}

.react-flow__handle {
    position: absolute;
    width: 8px;
    height: 8px;
    background: rgba(84, 84, 84, 0);
    border: 0px solid rgba(84, 84, 84, 0);
    border-radius: 100%;
}

.customTopHandle:before {
    box-sizing: border-box;
    display: inline;
    font-size: 40px;
    font-weight: normal;
    width: 100%;
    line-height: 1;
    color: #555;
    content: "\25BE";
    position: absolute;
    left:-8px;
    top:-13px;
    top:-24px;
}

.customBottomHandle:before {
    box-sizing: border-box;
    display: inline;
    font-size: 40px;
    font-weight: normal;
    width: 100%;
    line-height: 1;
    color: #555;
    content: "\25BE";
    position: absolute;
    left:-8px;
    top:-13px;
}

.customLeftHandle:before {
    box-sizing: border-box;
    display: inline;
    font-size: 40px;
    font-weight: normal;
    width: 100%;
    line-height: 1;
    color: #555;
    content: "\25B8";
    position: absolute;
    left:-3px;
    left:-14px;
    top:-16px;
}

.customRightHandle:before {
    box-sizing: border-box;
    display: inline;
    font-size: 40px;
    font-weight: normal;
    width: 100%;
    line-height: 1;
    color: #555;
    content: "\25B8";
    position: absolute;
    left:-4px;
    top:-16px;
}

.react-flow__edge-path:hover {
    stroke: #999999;
}

.dndnode .output {

}

.customEdgeButton {
    background: #f5d8d8;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    line-height: 1;
    width: 60px;
    height: 60px;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
        position: fixed;
        top: 145px;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }

    .dndflow aside {
        width: 30%;
        position: fixed;
        top: 70px;
        right: 0px;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10000;
        background: transparent;
    }
}

.graph {
    text-align: center;
    display: flex;
    height: 75vh;
}

.lastExpandedNode {
    stroke: red
}

.cluster rect {
    fill: #82a9d92b;
    rx:40;
    ry:40;
    cursor: pointer;
}

.cluster text {
    fill: #274d7b;
    font-weight: bold;
}

.nodes text {
    fill: white;
    font-weight: bold;
}

.nodes .clusteredEventsBar {
    rx:4;
    ry:4;
    fill: #80f0f0;
    fill: #f8bfbf;
    fill: lavenderblush;
    fill: whitesmoke;
}

.nodes .productsLine {
}

.tooltip {
    width: max-content;
    position: absolute;
    text-align: start;
    font: 12px sans-serif;
    padding: 0px 10px 0px 10px;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
}
.tooltipCluster {
    background-color: rgba(138, 149, 161, 0.85);
    color: white;
}
.tooltipAddFilter:before {
    box-sizing: border-box;
    display: inline;
    font-size: 30px;
    font-weight: normal;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\2295";
    position: absolute;
    left:-10px;
    top:-35px;
    text-align: start;
}
.tooltipRemoveFilter:before {
    box-sizing: border-box;
    display: inline;
    font-size: 30px;
    font-weight: normal;
    width: 100%;
    line-height: 1;
    color: rgb(184, 30, 30);
    content: "\2296";
    position: absolute;
    left:-10px;
    top:-35px;
    text-align: start;
}

.parentNode rect {
    rx:20;
    ry:20;
}

.childNode rect {
    rx:30;
    ry:30;
}

.loggedUser rect {
    fill: #7e9bbf;
    fill: rgba(242, 127, 51, 0.7);
    fill: rgba(1, 122, 135, 1);
}

.node .bizStepBG {
    fill: rgba(39, 77, 123, 0.25);
    fill: #4377b6;
    stroke: none;
}

.node .bizStepBGlogged {
    fill: rgba(39, 77, 123, 0.25);
    fill: #7e9bbf;
    fill: rgba(242, 127, 51, 1);
    fill: rgba(1, 122, 135, 1);
    stroke: none;
}

.node .bizStepIcon {
    fill: #274d7b;
    fill: whitesmoke;
    stroke: none;
}

.path {
    stroke: #274d7b;
    stroke-width: 0px;
    fill:none;
}

.details {
    text-align: left;
    background-color: ghostwhite;
    flex:1;
    padding:1em;
    overflow: scroll;
}

.edgePath path {
    stroke-width: 3px;
    fill: #274d7b;
}

.axis path {
    fill: none;
}







body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** css per il dag della storia dei prodotti */
.node rect {
  /*fill: #2593b8;
  stroke: #2593b8;*/
  /*stroke-width: 2px;*/
}

.eyeBg circle {
  fill: #fafafa;
  stroke: none;
  /*stroke-width: 2px;*/
}

.node text {
  font: 14px sans-serif;
}

.link {
  fill: none;
  stroke: #003e57;
  stroke-width: 2px;
}